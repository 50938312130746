import React from "react"

import "./index.css"

export default function ChangeLog() {
  return (
    <div>
      <div>
        <h2>Version 4.0.0</h2>
        <ul>
          <li>Changed primary domain from rohantinna.com to rohan.vc</li>
          <li>Added a blog hosted on Notion and served using Fruition & Cloudflare</li>
        </ul>
      </div>
      <div>
        <h2>Version 3.0.0</h2>
        <ul>
          <li>Switched to Gatsby from Vanilla React (React + React Router)</li>
        </ul>
      </div>
    </div>
  )
}
